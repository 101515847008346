import React from "react";
import { Header } from "../components";

const TermOfUse = () => {
  return (
    <>
      <Header />
      <div className="who-we-are" id="terms">
        <div className="heading">
          <h1>Terms of Use</h1>
          <p>Effectiveness date: May 2024</p>
        </div>
        <div className="content">
          <div className="wrap">
            <div className="one">
              <h2>General</h2>
              <p>
                These Terms of Use regulate the conditions of access and
                navigation through this website, owned and operated by the QR
                Creator (hereinafter, “the Company”).
              </p>
              <p>
                The access and use of the Website attributes the status of User
                to the person accessing the Website (hereinafter “the User”,
                “you”, “your”). Such status implies full acceptance of the
                present Terms of Use. If you decide to acquire a subscription
                plan, you will be automatically conferred with the status of
                “Customer” and will imply the acceptance of our Terms and
                Conditions. Therefore, we recommend that you carefully read the
                following Terms of Use before using this website. In the event
                that you do not agree to be bound by them, please do not
                continue to access or use this website.
              </p>
              <p>
                These Terms of Use may be updated at any time and without prior
                notice to the User, in order to comply with applicable laws and
                regulations, according to business matters or because of changes
                in the professional documents or functionalities of our website.
                The new Terms of Use will be applicable as soon as they are
                published on the website. Therefore, the User acknowledges and
                accepts that it is their responsibility to periodically review
                the applicable Terms of Use.
              </p>
              <p>
                The User accepts to use and navigate through this website
                complying with all the in force applicable laws and regulations,
                as well as in accordance with these Terms of Use.
              </p>
              <p>
                If any part or clause of these Terms of Use is declared null
                and/or void by any judicial decision, the remaining clauses will
                remain valid.
              </p>
            </div>
            <div className="two">
              <h2>Information and Website Services</h2>
              <p>
                The present website can only be used by adults with legal
                capacity. Therefore, if you do not meet these requirements in
                accordance with the applicable laws and regulations of your
                country, please do not visit or use this website. In this
                regard, the Company reserves the right to block access to this
                website, without prior notice, to Users who do not comply with
                these requirements.
              </p>
              <p>
                This Website offers its Users, natural or legal persons, to
                create various types of dynamic QR codes by using our website,
                among others, such as creating QR for menus, for Skype, for
                Paypal, for company profiles, and to connect to WiFi networks.
              </p>
              <p>
                In addition, we give Users the opportunity to easily customize
                and manage their QR codes, adding for example the User’s
                company's corporate colors, branding and logo, in addition to
                the alternative of downloading in different formats the created
                QR code.
              </p>
            </div>
            <div className="three">
              <h2>Code of conduct</h2>
              <p>
                The User must use this website and its content in accordance
                with the present Terms of Use, the currently applicable laws and
                regulations, common decency, and public order at all times. In
                particular, but without limitation, the User shall not
                (including but not limited to):
              </p>
              <ul>
                <li>
                  Not to impersonate another individual. The user can only
                  include personal data corresponding to their own identity and
                  that are adequate, pertinent, updated, exact and true.
                </li>
                <li>
                  Not to use the Website communicating any personal data of a
                  third person, without the express written consent of that
                  person. In the case of data of minors, the User must with the
                  express and written consent of their parents or legal
                  guardians.
                </li>
                <li>
                  Not to use the Website to transform and/or store information -
                  confidential or not - of a company, of which the User is not
                  its legal representative or person authorized, nor even to
                  send it to a third party.
                </li>
                <li>
                  Not to infringe the intellectual property or other rights
                  (trademarks, trade secrets, etc) of third parties by using the
                  Website’s service.
                </li>
                <li>
                  Not to use the information in the Website to carry out
                  illegal, deceptive or fraudulent activities, or for illegal or
                  unauthorized purposes;
                </li>
                <li>
                  Not to take any action that interferes or prevents the proper
                  functioning and/or that may affect the availability, security
                  and/or integrity of the Website;
                </li>
                <li>
                  Restrict or prevent any other User from using and enjoying
                  this website;
                </li>
                <li>
                  Not to insert and/or execute expressions, commands or codes
                  that, directly or indirectly, interfere, manipulate and/or
                  damage any element of the Website, especially its source code.
                  Likewise, the User agrees to not reverse engineer the software
                  to discover the Website’s source code and/or to scrape or
                  extract technical data from our Website;
                </li>
                <li>
                  Not upload inappropriate content in the use of our Services,
                  specifically this content must not infringe the rights of
                  third parties, must not be illegal, offensive, defamatory or
                  any other similar concept that may violate any applicable laws
                  or regulations.
                </li>
                <li>
                  Circumvent, disable or otherwise interfere with
                  security-related features of the website or features that
                  prevent or restrict use or copying of any content or that
                  enforce limitations on the use of the website;
                </li>
                <li>
                  Send or disseminate information, codes, or content that could
                  reduce, harm, disturb or impede any User’s access or normal
                  use of this website and/or damage or harm equipment belonging
                  to the Company (like servers or networks connected to the
                  website) or to other Users. This includes but is not limited
                  to malware, viruses, logic bombs, etc;
                </li>
                <li>
                  Not to transfer the license granted to download, access and
                  use the Website and/or any of his/her rights or obligations
                  under these Terms of Use without the express written consent
                  of the Company.
                </li>
                <li>
                  Not to breach the Company’s intellectual property rights, this
                  includes but is not limited to, use, copy, distribute, modify,
                  create derivative works of, transfer (by sale, resale,
                  license, sublicense, download or otherwise), reproduce,
                  distribute, display or disclose any content from this website
                  and/or any part of this website without prior written
                  authorization from the Company, with or without commercial
                  purposes;
                </li>
              </ul>
              <p>
                We reserve the right to block and/or suspend the access of Users
                who do not comply with the above obligations at any time and
                without prior notice, as well as to exercise all legal actions
                we deem appropriate to preserve our legitim interests, in order
                to maintain the quality, security, and availability of the
                present website.
              </p>
              <p>
                Moreover, the Company, in its sole discretion, may eliminate
                access to the Website for a User at any time without stating the
                reasons behind its decision.
              </p>
            </div>
            <div className="four">
              <h2>Limitation of Guarantees and Liability</h2>
              <p>
                The use of the present website is at the User’s own
                responsibility and risk. The User undertakes that by using our
                Services and the present website will not cause any damage to
                the Company and, where appropriate, they must respond for the
                damages caused to both the Company and any other third party.
              </p>
              <p>
                The Company makes its best efforts to ensure the availability of
                the website and the safe navigation of the User. However, access
                to this website may be punctually interrupted by maintenance
                tasks and/or the upload of updates of the contents. Also, we
                cannot guarantee that the website will be maintained without
                interruptions, delays, errors, or omissions for reasons beyond
                our control, as well as for possible damages that may be caused
                by third parties through illegitimate intrusion beyond our
                control.
              </p>
              <p>
                Notwithstanding the preceding, the Company will exert its best
                efforts to be as accurate as possible and to avoid mistakes in
                the content and information provided on the website. If you
                detect any inaccuracy, we kindly ask you to inform our Customer
                Service Team by email <span> info@qrcreator.com </span>or
                through our <span> Contact form.</span>
              </p>
              <p>
                Neither the Company nor its partners, suppliers, employees, or
                representatives will consequently be liable for any error or
                omission on the website or if the content and information of
                this website is used by a User or a third party for unlawful
                purposes and/or to cause damage to third parties or to the
                Company’s reputation.
              </p>
              <p>
                In the same way, the Company will not assume any responsibility
                for access to this website by Users under the required legal age
                according to their country’s applicable laws and regulations,
                being the responsibility of their parents and/or legal guardians
                to exercise an adequate control over the activity and/or use of
                the Internet by their dependent children or minors; to prevent
                their access to websites whose content is not suitable or
                recommended for minors, as well as the sending of personal data
                without the prior authorization of their parents or legal
                guardians. For further information, check our{" "}
                <span> Privacy Policy.</span>
              </p>
              <p>
                The Company will be exempt from any liability that may arise
                from the breach of these Terms of Use by the User.
              </p>
            </div>
            <div className="five">
              <h5>Intellectual Property Rights</h5>
              <p>
                <span> https://www.qrcreator.com</span> is a domain registered
                by the Company. Therefore, it cannot be used by third parties in
                connection with other products and/or services other than those
                offered on this website in any way that may cause confusion
                between our Users and/or discredit of the Company.
              </p>
              <p>
                The totality of the elements contained on this website, whether
                it be texts, articles, descriptions, images, graphics, sounds,
                videos, brands, logos, interactive features or any other
                element, its structure and design, the selection and
                presentation of the content and elements included therein, or
                any other element, and the software necessary for its operation,
                access and utilization are the property of the Company or, as
                the case may be, by the partners of the company with whom we
                have submitted the corresponding licenses. All the elements of
                the present website are protected by intellectual property
                rights that must be respected by the User. This matter is also
                warned with a copyright notice on the homepage of this Website.
              </p>
              <p>
                The reproduction, copying, distribution, public communication,
                transformation or, in general, any use of the protected elements
                of this website with or without commercial purposes, without the
                Company’s prior written consent is expressly and totally
                prohibited.
              </p>
              <p>
                The use of the Website does not under any circumstance transfer
                the Company’s intellectual property rights to the User. Instead,
                the user exclusively is the legitimate owner of the QR codes
                that he/she creates. As for the rest, the User is only granted
                access with a worldwide, limited, nonexclusive, non assignable,
                non-sublicensable, revocable license to access and use the
                Website for his/her personal use. However, such a license does
                not give the User the right to:
              </p>
              <ul>
                <li>
                  Reproduce, copy, distribute, publicly communicate or display,
                  transform or, in general, make use of any of the protected
                  elements of this Website for commercial purposes or not,
                  without the Company’s prior written consent. Any of the
                  aforementioned actions are expressly and totally forbidden and
                  will cause the termination of the license granted to the User.
                </li>
                <li>
                  Use of the features and functionalities offered by the Website
                  for other purposes other than those set out in these Terms of
                  Use.
                </li>
                <li>
                  Insert and/or execute expressions, commands or codes that,
                  directly or indirectly, interfere, manipulate and/or damage
                  any element of this Website, especially its source code.
                </li>
                <li>
                  Transmit any material that may infringe the intellectual
                  property rights of third parties.
                </li>
                <li>
                  Send unsolicited information and/or advertisements through the
                  Website.
                </li>
              </ul>
              <p>
                The access and use of this website does not under any
                circumstance transfer the company’s intellectual property rights
                to the User, nor the granting of a license or authorization.
              </p>
              <p>
                Moreover, note that the Company reserves all intellectual
                property rights that are not expressly indicated in these Terms
                of Use and that correspond to us in accordance with the
                currently applicable laws and regulations.
              </p>
              <p>
                The Company reserves the right to take all legal actions at our
                disposal if our intellectual property rights are infringed,
                including compensation for direct and indirect damages.
              </p>
            </div>
            <div className="six">
              <h2>Data Protection</h2>
              <p>
                The personal data collected for the provision of the service
                offered through this Website, as well as those obtained from
                your interaction with the Website will be processed in
                accordance with our <span> Privacy Policy.</span>
              </p>
              <p>
                Appropriate technical and organizational measures have been
                adopted by the Company in order to guarantee the confidentiality
                and security of the User’s personal data that are processed.
              </p>
            </div>
            <div className="seven">
              <h2>Jurisdiction and Applicable Laws</h2>
              <p>
                These Terms of Use shall be governed by and construed in
                accordance with the currently applicable Spanish law.
              </p>
              <p>
                In case of controversy or disagreement between the User and the
                Company arising from these Terms of Use, both parties agree to
                submit their resolution, at their free choice and expressly
                waiving any other jurisdiction to which they may be entitled, to
                the competent Courts and Tribunals of the place of residence of
                the User.
              </p>
              <p>
                The nullity, total or partial, of any of the provisions of these
                Terms of Use, will not affect the validity of the other
                provisions.
              </p>
              <p>
                The non-exercise by the Company of any right provided or derived
                from these Terms of Use shall not be construed, under any
                circumstances, as a waiver of said right, unless expressly and
                in writing waived by our Company or legal prescription of the
                action that in each case corresponds according to the applicable
                current legislation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermOfUse;
