import React from "react";
import { Header } from "../components";

const TermsCondition = () => {
  return (
    <>
      <Header />
      <div className="who-we-are" id="terms">
        <div className="heading">
          <h1>Terms and Conditions</h1>
          <p>Effectiveness date: August 2024</p>
        </div>
        <div className="content">
          <div className="wrap">
            <div className="one">
              <h2>General</h2>
              <p>
                The present Terms and Conditions regulates the download and use
                of the present Website: QR Creator (hereinafter, the/our
                “Website”), which is owned and operated by , a private company
                with FEI/EIN Number and its registered office at and contact
                email info@qrcreator.com (hereinafter, “the/our Company”, “we”,
                “us”, “our”). The Website is not affiliated with any other
                platforms or third parties.
              </p>
              <p>
                The use of the Website attributes the status of User to the
                person accessing the Website (hereinafter “the User”, “you”,
                “your”). Likewise, these Terms and Conditions explain the
                conditions of access to the services offered on this Website, to
                which the User is automatically linked at the time of creating
                an account herein. By clicking the “Sign up” button, the User
                clearly states that he/she has read, understood, and agrees to
                be bound by these Terms and Conditions. The creation of an
                account unrelated to the selection of a subscription plan, does
                not give place to any payment by the user.
              </p>
              <p>
                The confirmation of payment of a subscription plan to the
                services offered through this Website automatically confers the
                User the status of Customer. Hereinafter, therefore, the User
                who, after creating an account, has chosen a subscription plan
                will be referred to as 'Customer'.
              </p>
              <p>
                The Company reserves the right to update, without prior notice,
                these Terms and Conditions, in accordance with new legislative
                and/or jurisprudential requirements and/or business needs or
                interests, at any time and without prior notice to the User. The
                User is automatically bound to the new Terms and Conditions at
                the time they are published on the Website. Therefore, we
                recommend the User review them periodically.
              </p>
              <p>
                The Company reserves the right to block or suspend the access of
                a User to the Website in the event that he/she breaches any part
                of these Terms and Conditions.
              </p>
              <p>
                The nullity, total or partial, of any of the provisions of these
                Terms and Conditions by any judicial decision, will not affect
                the validity of the other provisions.
              </p>
              <p>
                For any doubt or question related to these Terms and Conditions,
                please contact us by email: info@qrcreator.com or by means of
                our
                <span> Contact Form.</span>
              </p>
            </div>
            <div className="two">
              <h2>About the Website’s Functionalities (Services)</h2>
              <p>
                We offer Users, natural or legal persons, to create various
                types of dynamic QR codes by using our website, among others,
                such as creating QR for menus, for Youtube videos, for company
                profiles, and to connect to WiFi networks.
              </p>
              <p>
                In addition, we give you the opportunity to easily customize and
                manage your QR codes, adding for example the User’s company's
                corporate colors, branding and logo, editing the content of the
                QR codes, as well as getting statistics and tracking analysis of
                the generated QR codes. Also, the User can export the data
                created by the QR, as well as share on social networks and
                various third-party platforms QR codes.
              </p>
              <p>All of the above together are called our “Services”.</p>
              <p>
                The User and/or Customer is informed that, among others that
                he/she is the sole owner and sole responsible party for the QR
                codes created, managed, stored and/or shared, as well as for the
                personal data and/or private information that he/she upload,
                creates, edits, incorporates, and/or stores on this Website:
              </p>
              <ul>
                <li>
                  The Company cannot guarantee the correct store of the created
                  QR if a technological blockage happens to occur, etc.
                </li>
                <li>
                  The Company will not be held responsible for the content of
                  the created QR code created by the User (Customer). In this
                  regard, the Company will not assume any liability if the QR
                  code - (by way of example but not limited to):
                </li>
                <div className="nested-list">
                  <li>
                    (i) contains personal data of individuals who have not given
                    their consent to do so;
                  </li>
                  <li>
                    (ii) contains third parties protected and/or confidential
                    information in respect of which it is not allowed the
                    collection, processing and communication without express
                    consent and/or authorization and/or that may harm the
                    reputation of third parties;
                  </li>
                  <li>
                    (iii) has been created making inappropriate or malicious use
                    of prompts and/or other Artificial Intelligence (AI)
                    functionalities that may be offered on this website.
                  </li>
                  <li>
                    (iv) contains inappropriate, illegal, offensive, racist,
                    pornographic, graphic violence, murder, profanity, slurs,
                    defamatory images, videos, documents and/or other content
                    that potentially disturbs subject matter.
                  </li>
                  <li>
                    (v) contains information - whatever its nature - that may
                    harm the reputation and/or business strategies of our
                    Company.
                  </li>
                </div>
                <p>
                  According to the above, the Company reserves the right - at
                  its sole discretion - to block the User’s access to the
                  present website, to delete the User (Customer)’s account
                  and/or cancel the subscription to our Services without prior
                  notification and without any obligation to reimburse the
                  Customer.
                </p>
                <li>
                  The access by third parties other than the User to the QR
                  codes will be carried out under the supervision and exclusive
                  responsibility of the User and/or Customer, excluding the
                  Company, its business partners and/or services providers from
                  any liability in this regard.
                </li>
              </ul>
              <p>
                In addition to the above, please be informed that under no
                circumstance does the Company does review, control, edit,
                monitor the QR codes created by the User and/or Customer in
                order to verify that the data provided does not infringe third
                parties rights.
              </p>
              <p>
                Please refer to the{" "}
                <b>
                  Exemption of Liability section of these Terms and Conditions
                </b>
                for further information regarding the Company’s
                responsibilities.
              </p>
              <p>
                Please be aware: the Company reserves the right to update
                features and/or functionalities of the Website at any time.
              </p>
            </div>
            <div className="three">
              <h2>Access and Use of the Website</h2>
              <p>
                You can access and navigate this Website as a User or as a
                Customer, provided that you are an adult according to the laws
                and regulations of your birth or country of residence and/or
                state.
              </p>
              <p>
                The User can navigate and use several of the Website’s services,
                such as the generation and customization of a QR code free of
                charge, without creating an account nor choosing a subscription
                plan.
              </p>
              <p>
                <u> Free Services without Registration</u>
              </p>
              <p>
                All Users can access several services without needing to
                register on our Website nor even having to choose a subscription
                plan and pay an initial fee, being this:
              </p>
              <ul>
                <li>Choose the type of QR code you desire to create;</li>
                <li>
                  Edit the QR code content and design, including the insertion
                  of third party links to your QR, and the insertion of images,
                  logos, videos, PDFs, etc;
                </li>
                <p>
                  Please note that in order to create an account in our Website,
                  and therefore register yourself, the following data will be
                  required: email and password.
                </p>
                <p>
                  <u>Payment Services with Registration</u>
                </p>
                <p>
                  The User/Customer who wishes to access the following services
                  must create an account on our Website and choose a
                  subscription plan, thus qualifying him/her as a Customer.
                  Otherwise, the following services will not be available to the
                  User:
                </p>
                <li>Download the QR code and enable its scannability;</li>
                <li>Save it in your private account section;</li>
                <li>
                  Share it through third party platforms or websites, or
                  channels.
                </li>
              </ul>
              <h5>
                For more information, see the 'Pricing and Payments' of these
                Terms and Conditions.
              </h5>
              <p>
                In the event that you provide us with a third party’s personal
                data and/or information, no matter if they are an adult or a
                minor, you guarantee to us that (i) you have informed the data
                subject about the purposes of processing his/her personal data
                and (ii) you have the data subject’s express consent to
                communicate us his/her personal data or the authorization of
                his/her parents or legal guardian to communicate his/her
                personal data to us, unless the data subject is a minor and you
                are his/her parent and/or legal guardian due to the common
                understanding that you can grant your consent to us to process
                the minor’s personal data by communicating it to us.
              </p>
              <p>
                For further information regarding how we process personal data
                you can check our <span> Privacy Policy</span>.
              </p>
            </div>
            <div className="four">
              <h2>Technical Means for Correcting Errors</h2>
              <p>
                If the User detects an error regarding his/her personal data,
                payment information and/or any other information provided by
                them is not exact, correct or completed, please contact our
                Customer Service through one of its channels.
              </p>
            </div>
            <div className="five">
              <h2>Prices and Payments</h2>
              <p>
                The price and payment of the subscription plan to the services
                offered through this Website chosen by the User will be governed
                by the information detailed in this section.
              </p>
              <p>
                In order to download the QR code, the User/Customer must select
                and subscribe to one of the following subscription plans for the
                Services offered on this site.
              </p>
              <ul>
                <li>
                  7-day Limited Access - Initial 7 days trial period at a cost
                  of $0.79. If the User does not request the cancellation of the
                  chosen subscription plan within 7 days from the date of
                  receipt of the email confirming the payment of the initial
                  cost, a recurring billing of $29.95 will be automatically
                  activated, at the same payment method chosen by the User to
                  make the payment of the initial cost.
                </li>
                <li>
                  7-day Full Access - Initial 7 days trial period at a cost of
                  $0.99. If the User does not request the cancellation of the
                  chosen subscription plan within 7 days from the date of
                  receipt of the email confirming the payment of the initial
                  cost, a recurring billing of $29.95 will be automatically
                  activated, at the same payment method chosen by the User to
                  make the payment of the initial cost.
                </li>
                <li>
                  Yearly Unlimited - One-time annual payment of $143.30 and
                  automatic annual renewal. It can be canceled at any time from
                  the date of receipt of the email confirming payment of the
                  relevant cost.
                </li>
                <p>
                  Once the payment has been made, a payment confirmation email
                  that explains the details and conditions of the subscription
                  plan chosen and how to cancel it (unsubscription) will be sent
                  by the Company. In this regard, please be aware that:
                </p>
                <li>
                  The “initial trial period” applies only to the Customer’s
                  first subscription. In case the Customer has, at any time,
                  canceled their subscription plan and wants to subscribe again,
                  the “initial trial period” will not apply and Customer will be
                  automatically charged with the corresponding recurring billing
                  from the date of such subscription. We may use information
                  such as the payment method, email address or the corresponding
                  ID to determine if the User has already been subscribed and,
                  therefore, is not eligible for the “initial trial period”.
                </li>
                <li>
                  When you purchase a subscription to our Services, you agree to
                  automatic or recurring billing on a four-weekly basis.
                </li>
                <li>
                  If you want to avoid the automatic “membership fee” being
                  applied you must cancel your subscription plan before the
                  “initial trial period” elapses.
                </li>
                <li>
                  You can cancel your contracted subscription plan as indicated
                  in the “The Cancellation of Subscription plans” section of
                  these Terms and Conditions.
                </li>
                <h5>Remarks</h5>
                <li>
                  Once processed and confirmed, the payment transaction will be
                  reflected on your bank statement, as general rule, with an
                  identifier corresponding to one of our telephone numbers.
                  However, this identifier may vary based on different factors
                  (e.g: business reasons, product/services purchased, origin and
                  destination of the transaction, method of payment, etc).
                </li>
                <li>
                  The only methods of payment accepted shall be those indicated
                  on this Website and/or on the payment page of the subscription
                  plans for the Services.
                </li>
                <li>
                  The Company reserves the right to update the types and terms
                  of subscription plans offered, at any time, depending on its
                  business objectives, strategy and needs, without prior notice
                  to Users and/or Customers.
                </li>
                <li>
                  The Company reserves the right to update the costs of the
                  subscription plans indicated herein at any time according to
                  its business goals, strategy and needs. The new costs will be
                  effective immediately, as soon as they are published on this
                  Website, without prior notice to Users and/or Customers.
                  However, the updated costs are not retroactive and therefore
                  will not affect Customers who have already purchased a
                  subscription plan before the update.
                </li>
                <li>
                  The Company also reserves the right to vary the costs of the
                  subscription plans indicated herein at any time on the basis
                  of the country and/or state from which you are accessing and
                  browsing through our Website, according to business goals,
                  strategy and interests.
                </li>
                <li>
                  Please note that the debit/credit card used in the payment
                  must be yours and you must be the legitimate holder of it.
                  Therefore, the Company will not assume any responsibility in
                  case you are not the holder or legitimate holder of the card
                  since this circumstance is beyond our reasonable control.
                  However, if necessary, the Company will provide appropriate
                  cooperation to the Customer and/or law enforcement and/or
                  judicial authorities, for the detection and/or investigation
                  of fraudulent operation(s) linked to the debit/credit card
                  used to make payment of the Services offered.
                </li>
                <li>
                  If recurring billing cannot be processed correctly on the
                  payment method selected by you, the Company reserves the right
                  to charge a partial payment of up to 5 USD of the available
                  balance in your payment method for keeping your Personal
                  Account/Private profile active in order for you to continue
                  accessing and enjoying the services and functionalities of our
                  website. Therefore:
                </li>
                <div className="nested-list">
                  <li>
                    Customers should ensure that its payment method is active
                    and/or it has sufficient funds for our charges to be
                    processed. For any payment or financial problems that
                    Customers might be experiencing, they must contact their
                    bank or financial service provider in order to regularize
                    it.
                  </li>
                  <li>
                    If the payment method you selected is nearing its expiration
                    date and/or has changed, you must contact our Customer
                    Service to update your payment information. Please note that
                    as long as your subscription is active, you are obligated to
                    pay the subscription fees and, therefore, the Company will
                    continue to bill you for subscription costs to your payment
                    method and you will remain liable for any uncollected
                    amounts.
                  </li>
                </div>
                <p>
                  Please be aware that the Company will not be liable and no
                  claims will be made to the Company if suspension or
                  termination of membership to our Services take place for the
                  reasons above stated.
                </p>
                <li>
                  Depending on the payment method used and the origin and
                  destination of the transaction, it may be subject to
                  applicable fees for currency exchange or price differences,
                  due to exchange commissions. The transaction may be subject to
                  currency exchange commission assessed by the User’s bank or
                  card issuer. The Company is not responsible for any commission
                  for currency exchange or surcharges and will not give any
                  compensation or refund for any surcharges imposed by the
                  User’s bank or card issuer.
                </li>
                <li>
                  Despite having the correct internal security protocols
                  regarding fraudulent use of payment methods, in no case, can
                  we assume responsibility for the User’s lack of security
                  measures in connection with the custody of the Customer’s
                  payment credit/debit card and security keys and/or passwords,
                  since this circumstance is beyond our reasonable control. We
                  will not be responsible for fraudulent use, although we will
                  make our best efforts to avoid this situation from occurring.
                </li>
              </ul>
            </div>
            <div className="six">
              <h2>Withdrawal right</h2>
              <p>
                In accordance with the applicable laws and regulations, the
                User/Customer is informed and, by registering and using the
                Services, acknowledges that the withdrawal right does not apply
                because QR codes are digital in nature and they are
                automatically consumed by the User/Customer at the time of
                downloading them.
              </p>
              <p>
                For information on how to cancel subscription plans, please
                refer to the 'Cancellation of subscription plans' section of
                these Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
