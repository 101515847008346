import React from "react";
import { Header } from "../components";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="who-we-are" id="terms">
        <div className="heading">
          <h1>Privacy Policy</h1>
          <p>Effective date: August 2024</p>
        </div>
        <div className="content">
          <div className="wrap">
            <div className="one">
              <p>
                Ebdani Trade SL(hereinafter, “the Company”, “We”, “Us” and the
                like) is firmly committed to regulatory compliance and the
                privacy and protection of personal data. Accordingly, in the
                present Privacy Policy (hereinafter, “this Policy”), the User
                (hereinafter “the User”, “you”, “your”) of QR Creator
                (hereinafter, “the/our app”) will find all the relevant
                information to better understand what We do with his/her
                personal data. If you do not agree with this Policy, please do
                not access and use our Website.
              </p>
              <p>
                We may update this Policy according to new legislative or
                jurisprudential requirements and/or business needs, among others
                reasons. Any updates or modifications of this Privacy Policy
                will be considered applicable from the moment of their
                publication on the Website. Therefore, the User is recommended
                to review this Policy regularly. This Policy will always be
                available on the Website so that the data subjects can consult
                it at all times.
              </p>
            </div>
            <div className="two">
              <h2>What Does Personal Data Mean?</h2>
              <p>
                For the purposes of this Privacy Policy, "personal data" means
                all data that identifies you or that could be used to identify
                you, such as the identification data you provided when
                registering on our website or when contacting our Customer
                Service Team, and/or other information provided by browsing this
                website or from your IP address.
              </p>
            </div>
            <div className="three">
              <h2>Who is your personal data controller?</h2>
              <p>
                Ebdani Trade SL (hereinafter, “the Company”, “We”, “Us” and the
                like) is the controller of the personal data the Company
                collects and processes in order to provide the Services offered
                through the present website.
              </p>
            </div>
            <div className="four">
              <h2>What does this Policy apply to?</h2>
              <p>
                This Policy applies to the personal data that We collect and
                process from you as a User and/or Customer of our Website as
                well as the services offered within it.
              </p>
              <p>
                Please bear in mind that in order to access and use our Website
                you must be older than fourteen (14) years of age.
              </p>
              <p>
                Our Website may contain icons and/or links to redirect you to
                websites of third parties. In this sense, please note that these
                third party websites are not operated by Us, so We cannot assume
                any responsibility for the information and content provided by
                them and/or the correct provision of the functionalities and/or
                services offered by these third parties, since We have no
                control over this. These third party websites are bound by their
                own cookie and privacy policies and you must consult them for
                further information on how your personal data is processed by
                them.
              </p>
            </div>
            <div className="five">
              <h2>When do We collect your personal data?</h2>
              <p>
                The personal data We collect from and about you is as follows:
              </p>
              <ul>
                <li>
                  <u> Direct interactions:</u> when you register on this
                  Website, you create and, if appropriate, download a QR code,
                  and/or you contact our Customer Service team by means such as
                  the Website’s contact form, postal address, email or telephone
                  number requesting for information.
                </li>
                <li>
                  <u> Automated technologies:</u> We may automatically collect
                  information like your IP address, the sections you consult on
                  our Website or the time that you remain on our Website by
                  using cookies, whether our own or from third parties. You can
                  consult our Cookie Policy to obtain more information about the
                  cookies of our Website, their purposes and any other
                  information of interest.
                </li>
                <li>
                  <u> Payment data:</u> We will only request your payment
                  information once you have registered by creating an account on
                  our Website in order to download and share a QR code. The
                  captured data will be sent securely to our certified payment
                  service providers for the authorization of the transaction. In
                  no case do We visualize or access the complete data of your
                  credit or debit card. It is the Customer’s responsibility not
                  to enter, provide or send his/her card information through
                  channels not authorized by the Company.
                </li>
                <p>
                  Please be informed that you only are required to provide the
                  personal data and information strictly necessary for the
                  fulfillment of the purposes detailed below. Therefore, not
                  providing this information could result in the inability to
                  assist you and/or address your informational requests, etc.
                  With this in mind, please note that if you provide Us with
                  more information than is expressly required, you consent to
                  the processing of it for the informed purpose/s for which
                  you’re sending it.
                </p>
                <p>
                  The User and/or Customer must provide accurate, updated and
                  complete personal data and/or private information in order to
                  fulfill the purposes detailed below. We will not assume any
                  type of responsibility for circumstances that are beyond our
                  reasonable control, such as being provided with erroneous,
                  incomplete and/or fraudulent information from the User.
                </p>
                <p>
                  If you provide Us with personal data from third parties,
                  whether these minors or adults, you guarantee to Us that you
                  have informed them about the purposes for which their personal
                  data will be processed by the Company and that you have
                  obtained, if appropriate, their prior and express consent to
                  communicate Us their personal data.
                </p>
                <p>
                  Equally, if you provide Us with the personal data of a minor,
                  you guarantee to Us that you are his/her parent and/or legal
                  guardian and/or that you have obtained the authorization of
                  his/her parents or legal guardian to communicate the minor’s
                  personal data with us. The Company will not accept any
                  responsibility for any breach of this requirement.
                </p>
                <p>
                  If you, the User, are a minor, it is necessary to have the
                  prior consent of your parents or legal guardians to include
                  your personal data on his Website. The company will not accept
                  any responsibility for any breach of this requirement.
                </p>
                <p>Additionally, bear in mind that if:</p>
                <li>
                  The Company has received an adult’s personal data and/or
                  private information in order to create a QR code on his/her
                  behalf by an individual without having requested his/her
                  express consent to communicate Us his/her personal data, the
                  Company in such a case reserves the right to inform the data
                  subject about how his/her personal data and/or private
                  information has been collected and, where appropriate, will
                  ask the individual who provided the personal data to the
                  Company to confirm he/she requested the consent of the data
                  subject and, where appropriate, to amend this circumstances.
                  In any case, the data subject will at all times have the
                  possibility to object to the processing of his/her personal
                  data by the Company, in which case, We will proceed to cancel
                  the user’s account as well as other measures that are
                  considered appropriate.
                </li>
                <li>
                  The Company has received a minor’s personal data and/or
                  private information to create a QR code provided by an
                  individual who is not his/her parent and/or legal guardian, as
                  soon as the Company detects and/or becomes aware of this
                  situation, it will proceed to take the appropriate measures to
                  fix the situation.
                </li>
              </ul>
            </div>
            <div className="six">
              <h2>What do We use your personal data for?</h2>
              <p>
                Please be informed that you only are required to provide
                personal data and information that is strictly necessary to
                fulfill the purposes explained below. In this regard, if you
                provide Us with more information than is expressly required, you
                consent to its processing for the purpose(s) for which it was
                granted.
              </p>
              <p>
                Having said that, We use your personal data for the following
                purposes:
              </p>
              <ul>
                <li>
                  To manage the creation, storage and sharing of QR codes
                  provided through our Website;
                </li>
                <li>
                  To collect the fees of the subscription plans to the services
                  offered through the Website, the verification of your payment
                  information, accountancy and billing tasks;
                </li>
                <li>
                  To manage and address your information queries regarding our
                  Website and/or services offered through it, as well as your
                  claims or requests, through one of our several means of
                  contact, about Us and/or our services and/or to attend to your
                  assistance and refund requests;
                </li>
                <li>
                  To carry out periodic checks against fraudulent, unauthorized
                  or illegal activity on/or through our Website;
                </li>
                <li>
                  To comply with our legal obligations, legal requirements,
                  laws, and regulations and/or to respond to judicial, police or
                  the corresponding authorities’ requirements;
                </li>
                <li>
                  To protect, investigate, and deter fraudulent, unauthorized,
                  or illegal activity on/through our Website;
                </li>
                <li>
                  To improve our Website’s functionalities and services offered
                  by analyzing disaggregated data not associated with a
                  particular user’s personal information.
                </li>
                <h5 className="mt-4">Remarks</h5>
                <li>
                  For security reasons, in the event you contact the Customer
                  Service, they may ask you to provide personal data and/or
                  information to verify your identity and that you are our
                  Customer, before proceeding to address your request.
                </li>
                <li>
                  When applicable, if you contact our Customer Service via
                  telephone, your phone call will be recorded for security
                  purposes and to improve the quality of the telephone service,
                  this being a standard practice in many companies. You will be
                  informed in advance that your call will be recorded and you
                  will also have access to the information on how the Company
                  processes the personal data you will be required to provide
                  during the call to verify your identity as a Customer and the
                  subscription plan you have contracted in order to properly
                  attend to your request. Please be informed that your call will
                  only be recorded if you give us your express consent for it,
                  which will be understood to be granted if you do not hang up
                  and wait to be attended to by an agent from the Customer
                  Service team. If you do not want to be recorded, then you must
                  hang up and not continue with your call and contact our
                  Customer Service by email <span> info@qrcreator.com</span> .
                </li>
                <li>
                  The Company will not be responsible for any graphic content,
                  information and/or document that the User (Customer) may
                  upload for creating a QR code which contains personal data of
                  a third party without having the prior consent from the data
                  subject. In the event of violating any of the applicable data
                  protection regulations, the User (Customer) will be solely
                  responsible for said non-compliance.
                </li>
                <li>
                  The Company will not be held responsible if the User
                  (Customer) uploads graphic content, information and/or
                  document to this website in order to generate a QR code that
                  may result in a non content restricted or prohibited by law
                  and/or that may result in content that is unsuitable and/or
                  inappropriate for viewing and/or sharing because it affects a
                  person's privacy, self-image and/or other protected right of
                  an individual.
                </li>
              </ul>
            </div>
            <div className="seven">
              <h2>
                What is the legal basis for processing your personal data?
              </h2>
              <p>
                We only process your personal data when there is a legal basis
                for doing so. The legal basis will depend on the reason(s) why
                We collect and process your personal data. In almost all cases
                the legal basis will be:
              </p>
              <ul>
                <li>
                  The execution of the contract that you provided Us with for
                  the processing of your personal data in order to be able to
                  provide you with the Services offered through the Website and
                  other purposes informed at the time of collection of your
                  data.
                </li>
                <li>
                  The consent you provided us, among others, in order to contact
                  our Customer Service Department, including contacting them by
                  means of telephone.
                </li>
                <li>
                  To comply with our legal obligations and/or to attend
                  administrative, judicial, police, or the corresponding
                  authorities’ official requirements.
                </li>
                <li>
                  Our legitimate interests in (i) offering an enhanced user
                  experience when you access and use the Website and operating
                  it efficiently, (ii) providing advertisements to users
                  according to their interests; (iii) monitoring fraudulent
                  activity in order to preserve the security and integrity of
                  our Website; (iv) defending and addressing our rights in the
                  event that a claim regarding our Website and/or Services may
                  arise.
                </li>
              </ul>
            </div>
            <div className="eight">
              <h2>How long do We keep your personal data for?</h2>
              <p>
                We will keep your personal data only for as long as it is
                necessary to fulfill the purposes for which they are being
                processed and, beyond that, your personal data will be retained,
                and securely blocked, for the necessary retention period to meet
                our legal obligations and/or for our legitimate reasons (i.e to
                address claims in connection with the services offered through
                the Website; to finish the investigation of detected fraudulent
                or illicit activity).
              </p>
              <p>
                Once the necessary retention period has ended, your personal
                data will be securely deleted from our information systems.
              </p>
            </div>
            <div className="nine">
              <h2>Who do We share the User’s personal data with?</h2>
              <p>
                We may communicate your personal data to the following third
                parties, including but not limited to:
              </p>
              <ul>
                <li>(i) our main service providers, where appropriate;</li>
                <li>
                  (ii) our own organization or other organizations within our
                  group or under the same parent company, including the merchant
                  of records if applicable;
                </li>
                <li>(iii) other providers such as Google Inc;</li>
                <li>
                  (iv) administrative, police or judicial authorities as long as
                  the data is required in accordance with the applicable law and
                  regulations;
                </li>
                <li>
                  (v) law firms to respond to claims regarding our Website or
                  services offered through it;
                </li>
              </ul>
              <p>
                The third parties mentioned above may have their headquarters
                outside the European Economic Area (EEA), therefore, your
                personal data will be object of international transfers. In any
                event, if a personal data international transfer is deemed
                appropriate, We inform you that We will only transfer personal
                data to recipients located in countries that ensure an adequate
                level of data protection or, failing that, offer the appropriate
                safeguards required by the current applicable law and
                regulations on the protection of personal data, in order to
                ensure that your personal data is properly protected and that
                your individual rights and freedoms are guaranteed. In this
                regard, please be informed that the third parties mentioned
                above are committed with standard contractual clauses approved
                by the EU Commission certification in case of transfers to the
                USA.
              </p>
            </div>
            <div className="ten">
              <h2>How do We protect your personal data?</h2>
              <p>
                Your trust is very important to Us. For this reason, your
                personal data is stored confidentially and securely in our
                information systems. We have established the appropriate
                technical and organizational measures to safeguard and protect
                your personal data against illegal or unauthorized access, loss
                or accidental destruction, damage, use, and illegal or
                unauthorized disclosure.
              </p>
            </div>
            <div className="eleven">
              <h2>What rights do you have and how can you exercise them?</h2>
              <p>
                You may exercise your data protection rights (i.e access,
                erasure, etc) by written request addressed to our Company to the
                following email: <span> info@qrcreator.com</span>and/or by
                telephone.
              </p>
              <p>
                As a general rule, We will respond to data protection rights
                requests within one (1) month. Occasionally, this period may be
                extended by two (2) further months where necessary, taking into
                account whether your request is particularly complex or you have
                made several requests. In this case, We will notify you of this
                event and explain the reasons for the delay to you.
              </p>
              <p>
                Finally, We inform you that should you consider it appropriate,
                you have the right to lodge a complaint before the corresponding
                Supervisory Authority, especially if you consider that your data
                protection rights have not been adequately addressed.
              </p>
              <p>
                We remain at your disposal for any doubts or questions you may
                have about this Policy, please contact Us by email at
                <span> info@qrcreator.com</span>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
