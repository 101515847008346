import React from "react";
import { Header } from "../components";

const CookiePolicy = () => {
  return (
    <>
      <Header />
      <div className="who-we-are" id="terms">
        <div className="heading">
          <h1>Cookie Policy</h1>
          <p>Effective date: May 2024</p>
        </div>
        <div className="content">
          <div className="wrap">
            <div className="one">
              <p>
                We hereby inform the users (hereinafter, “you”) that this
                website (hereinafter, our/this “Website”) uses cookies. The
                following Cookie Policy explains the cookies used by this
                website, owned and operated by QR Creator (hereinafter, “we”).
              </p>
              <p>
                We may update this Cookies Policy according to new or
                legislative or jurisprudential requirements and/or business
                needs, among others. Therefore, the User is recommended to
                review this policy regularly.
              </p>
            </div>
            <div className="two">
              <h2>What are Cookies and what are they used for?</h2>
              <p>
                "Cookies" are small files that are installed on the hard disk or
                in the browser of your computer or mobile device with different
                purposes such as adapting the Website's format according to the
                device used by you; collecting statistical information about our
                Website; facilitating your navigation on this Website; allowing
                the reproduction and display of multimedia content, among
                others.
              </p>
              <p>
                The memorized data recorded by cookies takes up a small space on
                a device's memory and does not harm the device in any way.
              </p>
            </div>
            <div className="three">
              <h2>When do we install cookies?</h2>
              <p>
                Accessing and/or browsing this Website and/or purchasing the
                services offered therein implies that cookies can be installed,
                which may be session or persistent cookies, and our own or those
                of third parties.
              </p>
              <ul>
                <li>
                  <u> Session cookies:</u> are those that are automatically
                  removed when closing the browser of your device.
                </li>
                <li>
                  <u> Persistent cookies:</u> are those that remain installed
                  for a certain time in the browser of your device.
                </li>
                <li>
                  <u> Owned cookies:</u> are those that are sent to the user's
                  device from our computer equipment or this Website, managed by
                  us, and whose information is collected for our own purposes.
                </li>
                <li>
                  <u> Third-party cookies:</u> are those that are sent to the
                  user's device from computer equipment or Website managed by
                  another company (service providers we have contracted for this
                  purpose) and whose information can be collected for our own
                  purposes and/or to manage and improve the services that these
                  third-party companies offer (e.g links to social networks that
                  allow us to share our content).
                </li>
                <p>
                  To install cookies in your device's browser, we need your
                  express consent, although there are other cookies that are
                  exempt from such consent. The exempt cookies are:
                </p>
                <li>User Login Cookies</li>
                <li>
                  User authentication or identification cookies (session cookies
                  only)
                </li>
                <li>User Security Cookies</li>
                <li>Multimedia player session cookies</li>
                <li>Session cookies to balance the load</li>
                <li>Cookies to customize the user interface</li>
                <li>
                  Some add-on cookies (plug-in) to exchange social content
                </li>
                <p>
                  We have enabled adequate mechanisms to obtain your consent for
                  the installation of cookies that require it. However, please
                  be informed that, in accordance with the applicable laws and
                  regulations, (i) it will be understood that your consent has
                  been given if you modify the configuration of the browser by
                  disabling the restrictions that prevent the installation of
                  cookies and (ii) when the installation of cookies is necessary
                  to provide you with a service offered on our Website that you
                  have expressly requested, your consent will not be necessary.
                </p>
                <p>
                  The use of third-party cookies is subject to the Cookies and
                  Privacy Policies of these third parties. Therefore, you must
                  consult the websites of said third parties to obtain more
                  information or if you want to change your preferences for
                  setting these cookies. We will not be held responsible for the
                  content or the veracity of the aforementioned policies of said
                  third parties.
                </p>
                <p>
                  The following types of cookies can be installed in your
                  browser:
                </p>
                <li>
                  <u> Technical:</u> cookies that allow the management and
                  operation of our Website and enable its functions and
                  services, for example, to remember the service contracted, to
                  carry out the purchase process and manage payment, or to share
                  content through social networks, etc.
                </li>
                <li>
                  <u> Analytical:</u> cookies that allow us to analyze the use
                  you make of our Website and to recognize which information
                  and/or services interest you the most in order to improve the
                  quality of our services, or even to show you the QR codes
                  analytical results.
                </li>
                <li>
                  <u> Personalization:</u> cookies that allow you to remember
                  information so that you access our Website with certain
                  characteristics that can differentiate your user experience
                  from that of others, for example, the appearance of the
                  website depending on the type of your browser, or the region
                  from which you access the website, etc.
                </li>
              </ul>
              <table data-testid="table" className="table">
                <thead>
                  <tr>
                    <th>Cookie name</th>
                    <th>Supplier</th>
                    <th>Type</th>
                    <th>Storage period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>qrState</td>
                    <td>Owned</td>
                    <td>Technical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>user</td>
                    <td>Owned</td>
                    <td>Technical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>prices</td>
                    <td>Owned</td>
                    <td>Technical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>token</td>
                    <td>Owned</td>
                    <td>Technical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>NA</td>
                    <td>Paddle</td>
                    <td>Technical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>NA</td>
                    <td>Cloudinary</td>
                    <td>Technical</td>
                    <td>From session to 10 years</td>
                  </tr>
                  <tr>
                    <td>utm_source</td>
                    <td>Owned</td>
                    <td>Analytical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>utm_campaign</td>
                    <td>Owned</td>
                    <td>Analytical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>utm_medium</td>
                    <td>Owned</td>
                    <td>Analytical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>cookiePolicy</td>
                    <td>Owned</td>
                    <td>Analytical</td>
                    <td>Session</td>
                  </tr>
                  <tr>
                    <td>_ga</td>
                    <td>Google Analytics Universal</td>
                    <td>Analytical</td>
                    <td>2 years</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="four">
              <h2>How can the User block or refuse cookies?</h2>
              <p>
                You can, at any time, revoke the consent given for the
                installation of this Website's cookies, by configuring the
                settings of the browser of your computer or mobile device. The
                instructions about how to manage or delete cookies for the most
                common browsers can be found at the following links:
              </p>
              <ul>
                <li>
                  <span>Google Chrome.</span>
                </li>
                <li>
                  <span>Mozilla Firefox.</span>
                </li>
                <li>
                  <span>Internet Explorer.</span>
                </li>
                <li>
                  <span>Safari.</span>
                </li>
                <li>
                  <span>Chrome for Android.</span>
                </li>
                <li>
                  <span>Opera.</span>
                </li>
              </ul>
              <p>
                Please bear in mind that in the event that you decide to block,
                disable, or delete cookies, certain functions of this Website
                that require the use of cookies are not available to you.
              </p>
              <p>
                You can also configure the browser of your computer or mobile
                device to accept or reject all cookies by default, or to be
                notified of the receipt of cookies and to decide each time
                whether or not to allow the installation of the same.
              </p>
              <p>
                Moreover, you can activate the following options in order to
                limit the scope of the cookies installed or their operation:
              </p>
              <ul>
                <li>
                  private browsing, through which your browser stops saving
                  browsing history, website passwords, cookies, and other
                  information of the pages you visit, or
                </li>
                <li>
                  the non-tracking function, by which the browser asks the
                  websites you visit not to track your browsing habits, for
                  example, to serve advertisements of your interest in the sites
                  you visit.
                </li>
              </ul>
            </div>
            <div className="five">
              <h2>How can you reach us?</h2>
              <p>
                If, at any time, you have any questions regarding the use of
                cookies on our Website, please do not hesitate to contact us at
                <span> info@qrcreator.com</span>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;
